import {
  RECEIVE_ALL_LOTS,
  RECEIVE_SINGLE_LOT,
} from './basicActions'

export function lots(
  state = {
    lotsById: {},
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ALL_LOTS:
      let newLots = action.lots.reduce(
        (obj, item) => Object.assign(obj, { [item.lot_identification_string]: item }),
        {}
      );
      return {
        ...state,
        lotsById: newLots,
      };
    case RECEIVE_SINGLE_LOT:
      return {
        ...state,
        lotsById: {
          ...state.lotsById,
          [action.lot.lot_identification_string]: action.lot,
        },
      };
    default:
      return state;
  }
}
