import React, {useContext, useEffect, useState} from "react";
import {
  Box,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex, Heading,
  Icon,
  IconButton,
  Link,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {FiHome, FiLogOut, FiMenu} from "react-icons/fi";
import {useKeycloak} from "@react-keycloak/web";
import { BASE_URL, Paths } from '../constants/general'
import { NavLink } from 'react-router-dom'

export default function Layout({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bg = useColorModeValue("gray.100", "gray.900");
  const { keycloak, initialized } = useKeycloak();
  const [logoutUrl, setLogoutUrl] = useState("");
  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      setLogoutUrl(
        keycloak.createLogoutUrl({ redirectUri: BASE_URL })
      );
    } else {
      setLogoutUrl("");
    }
  }, [keycloak, initialized]);
  if (!initialized || !keycloak || !keycloak.authenticated) {
    return children;
  } else {
    return (
      <Box minH="100vh" bg={bg}>
        <SidebarContent
          onClose={() => onClose}
          logoutUrl={logoutUrl}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              logoutUrl={logoutUrl}
            />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {children}
        </Box>
      </Box>
    );
  }
}

const SidebarContent = ({ onClose, logoutUrl, ...rest }) => {
  const [links, setLinks] = useState([
    { name: "Home", icon: FiHome, url: "/" },
    { name: "Esci", icon: FiLogOut, url: "/" },
  ]);
  useEffect(() => {
    if ( !logoutUrl) {
      return;
    }
    setLinks((old) => {
      return [
        { name: "Home", icon: FiHome, url: "/" },
        { name: "Inserisci lotto", url: Paths.INSERISCI_LOTTO},
        { name: "Ricerca lotto", url: Paths.RICERCA_LOTTO },
        { name: "Esci", icon: FiLogOut, url: logoutUrl },
      ];
    });
  }, [logoutUrl]);
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Heading as={'h3'} size={"md"}>Pesca blockchain</Heading>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {links.map((link) => (
        <NavItem key={link.name} icon={link.icon} url={link.url}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, url, ...rest }) => {
  return (
      <Link href={url} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "cyan.400",
            color: "white",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};
