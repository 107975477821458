import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { Field } from 'formik'

export default function FormInputField(props){
  return (
    <Field  name={props.name}>
      {({ field, form }) => (
        <FormControl
          isInvalid={
            form.errors[props.name] && form.touched[props.name]
          }
          isRequired={props.required}
        >
          <FormLabel>
            {props.label}
          </FormLabel>
          <Input
            {...field}
            id={props.name}
            name={props.name}
            type={props.type}
            variant="filled"
            disabled={props.disabled}
          />
          <FormErrorMessage>
            {form.errors[props.name]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}