export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const AUTH_URL = process.env.REACT_APP_AUTH_URL
export const BASE_URL = process.env.REACT_APP_BASE_URL

export const Paths = Object.freeze({
  RICERCA_LOTTO: "/ricerca-lotto",
  INSERISCI_LOTTO: "/inserisci-lotto",
  LOTTO: "/lotto"
})
export const MessageStatuses = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
})