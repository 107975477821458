import {
  Box,
  Button,
  GridItem,
  Heading,
  Progress, SimpleGrid,
  VStack,
} from '@chakra-ui/react'
import React  from 'react'
import Layout from '../Layout'
import ProtectedPage from '../ProtectedPage'
import { Form, Formik } from 'formik'
import FormInputField from '../common/FormInputField'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../../constants/general'

export function RicercaLotto(){
  const navigate = useNavigate();
  return (
    <ProtectedPage>
      <Layout>
        <Box width={"100%"} bg={"white"} padding={"10px"}>
          <Formik
            initialValues={{ lot_identification_string: "" }}
            onSubmit={(values) => {
              navigate(Paths.LOTTO + "/" + values.lot_identification_string)
            }}
            enableReinitialize
          >
            <Form>
              <VStack spacing={3} alignItems="flex-start" marginBottom={"40px"}>
                <Heading as="h4" size="md">
                  Ricerca lotto
                </Heading>
              </VStack>
              <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
                <GridItem colSpan={2} marginLeft={"25%"} marginRight={"25%"}>
                  <FormInputField
                    name={"lot_identification_string"}
                    type={"text"}
                    label={"Identificativo lotto"}
                    required={true}
                  />
                </GridItem>
                <GridItem marginTop={"40px"} colSpan={2}>
                  <Button
                    colorScheme="blue"
                    type="submit"
                    size="lg"
                    w="full"
                  >
                    Cerca lotto
                  </Button>
                </GridItem>
              </SimpleGrid>
            </Form>
          </Formik>
        </Box>
      </Layout>
    </ProtectedPage>
  )
}