import { applyMiddleware, createStore } from "redux";
import combinedReducers from "./combinedReducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logger } from 'redux-logger/src'

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "lots", "stoccaggio"
  ],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = createStore(
  persistedReducer,
  applyMiddleware( thunk, logger)
);

// Persist the redux state in localstorage
export const persistor = persistStore(store);
