import './App.css';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { persistor, store } from './redux/store'
import { ChakraProvider } from "@chakra-ui/react";
import { Home, Layout } from './components/pages/Home'
import { theme } from './constants/theme'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { InserisciLotto } from './components/pages/InserisciLotto'
import { RicercaLotto } from './components/pages/RicercaLotto'
import { LottoPage } from './components/pages/LottoPage'
import { Paths } from './constants/general'
import { keycloak } from './keycloak'

function App() {
  const handlePageChange = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="App">
      <ReactKeycloakProvider
        authClient={keycloak}
      >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={"/"} render={handlePageChange}>
            <ChakraProvider theme={theme}>
              <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path={Paths.INSERISCI_LOTTO} element={<InserisciLotto/>}/>
                <Route path={Paths.RICERCA_LOTTO} element={<RicercaLotto/>} />
                <Route path={Paths.LOTTO + "/:codice"} element={<LottoPage/>} />
              </Routes>
            </ChakraProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
