import axios from "axios";
import { BACKEND_URL } from '../../constants/general'
import { receiveAllLots, receiveSingleLot } from './basicActions'
import { keycloak } from '../../keycloak'

const Paths = Object.freeze({
  lot: "/ops/lot",
  lot_full_data: "/public/lot_full_data"
});

export const getStandardConfig = () => {
  return {
    headers: {
      Authorization: "Bearer " + keycloak.token
    },
    timeout: 60000,
  };
};


export function fetchAllLots() {
  return (dispatch) => {
    return axios
      .get(BACKEND_URL + Paths.lot, getStandardConfig())
      .then((response) => {
        dispatch(receiveAllLots(response.data));
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  };
}

export function fetchSingleLot(lot_id_string) {
  return (dispatch) => {
    return axios
      .get(BACKEND_URL + Paths.lot_full_data + "/" + lot_id_string, getStandardConfig())
      .then((response) => {
        dispatch(receiveSingleLot(response.data));
      })
      .then((response) => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  };
}

export function createNewLot(data) {
  return (dispatch) => {
    return axios
    .post(
      BACKEND_URL + Paths.lot,
      data,
      getStandardConfig()
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(() => {
      return Promise.reject();
    });
  };
}