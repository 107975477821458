import React from "react";
import { Button, Flex, Heading, Stack, VStack } from '@chakra-ui/react'
import { useKeycloak } from '@react-keycloak/web'

export default function ProtectedPage({ children }) {
  const { keycloak, initialized } = useKeycloak();
  if (!initialized){
    return null
  }
  if (!keycloak.authenticated) {
    return (
        <Flex minH={'100vh'} flexDir={'column'} justifyContent={'center'} alignItems={'center'} >
          <Heading as='h3' size={"lg"} margin={"20px"}>Progetto pesca blockchain</Heading>
          <Stack direction="column" align="center">
            <Button
              colorScheme='blue'
              size="lg"
              onClick={() => {
                if (initialized) {
                  keycloak.login({locale: 'it'});
                }
              }}
            >
              Login
            </Button>
          </Stack>
        </Flex>
    );
  }
  return <>{children}</>;
}
