import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import styles from "../../styles/Home.module.css";
import { Box } from '@chakra-ui/react'
import ProtectedPage from '../ProtectedPage'
import Layout from '../Layout'

export function Home(){
  const { keycloak } = useKeycloak();
  const parsedToken = keycloak?.tokenParsed;
  const welcomeMessage =
    keycloak?.authenticated || (keycloak && parsedToken)
      ? `Benvenuto ${parsedToken?.preferred_username ?? ''}`
      : 'Benvenuto, effettua il login'
  return (
    <ProtectedPage>
      <Layout>
        <Box className={styles.container}>
          <main className={styles.main}>
            <p className={styles.description}>
              {welcomeMessage} <br />
              <br />
              Seleziona una operazione
            </p>
          </main>
        </Box>
      </Layout>
    </ProtectedPage>
  )
}