import {
  Alert, AlertIcon,
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text, Th, Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProtectedPage from "../ProtectedPage";
import { useParams } from "react-router-dom";
import { MessageStatuses } from "../../constants/general";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleLot } from "../../redux/lots/backendActions";
import {
  FISH_TOOLS,
  FISH_TOOLS_LABELS,
  PublicLotto,
} from '../../constants/lotto'

export function LottoPage() {
  const params = useParams();
  const [wait, setWait] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    status: MessageStatuses.ERROR,
    text: "",
  });
  const [showedLotto, setShowedLotto] = useState(new PublicLotto());
  const dispatch = useDispatch();
  const lotsById = useSelector((state) => state.lots.lotsById);
  useEffect(() => {
    if (!params.codice) {
      setWait(false);
      setMessage({
        show: true,
        status: MessageStatuses.ERROR,
        text: "Codice lotto non valido",
      });
    } else {
      setWait(true);
      dispatch(fetchSingleLot(params.codice))
        .then(() => {
          setWait(false);
          setMessage({
            show: false,
            status: MessageStatuses.ERROR,
            text: "",
          });
        })
        .catch(() => {
          setWait(false);
          setMessage({
            show: true,
            status: MessageStatuses.ERROR,
            text: "Errore nella ricerca del lotto",
          });
        });
    }
  }, [params.codice, dispatch]);
  useEffect(() => {
    if (params.codice) {
      if (lotsById[params.codice]) {
        setShowedLotto(lotsById[params.codice]);
      }
    }
  }, [params.codice, dispatch, lotsById]);
  return (
    <ProtectedPage>
      <Layout>
        <Box width={"100%"} bg={"white"} padding={"10px"}>
          <VStack spacing={3} alignItems="flex-start" marginBottom={"40px"}>
            {wait ? (
              <Heading as="h4" size="md">
                Ricerca in corso
              </Heading>
            ) : (
              <Heading as="h4" size="md">
                Lotto {params.codice}
              </Heading>
            )}
          </VStack>
          {
            !message.show && (
              <Skeleton isLoaded={!wait}>
                <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
                  <GridItem colSpan={2}>
                    <TableContainer marginBottom={"30px"}>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th fontSize={"md"} color={"darkblue"}>
                              Dati in database
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>
                              <Text as="b">Identificativo partita</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.lot_identification_string}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Posizione pesca (latitudine)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.position_lat}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Posizione pesca (longitudine)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.position_long}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Identificativo UE imbarcazione</Text>
                            </Td>
                            <Td>
                              <Text>
                                {showedLotto.ship_ue_identification_number}
                              </Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Nome imbarcazione</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.ship_name}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Data di pesca</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.fish_time}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">
                                Tipo di attrezzo di pesca utilizzato
                              </Text>
                            </Td>
                            <Td>
                              <Text>
                                {
                                  FISH_TOOLS_LABELS[
                                    Object.keys(FISH_TOOLS).find(
                                      (key) =>
                                        FISH_TOOLS[key] === showedLotto.fish_tool
                                    )
                                    ]
                                }
                              </Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Porto di sbarco</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.landing_port}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Data di sbarco/di prima vendita</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.landing_port_time}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Application id Algorand</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.algorand_id}</Text>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <TableContainer>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                        <Th fontSize={"md"} color={"darkblue"}>
                          Dati in blockchain
                        </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>
                              <Text as="b">Application id Algorand (appid)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.appid}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Id lotto (lot_id)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.lot_id}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Localizzatore dati lotto (lot_url)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.lot_url}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Hash dati lotto (sha256_lot_hash)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.sha256_lot_hash}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Text as="b">Identificativo dati lotto in IPFS (ipfs_cid)</Text>
                            </Td>
                            <Td>
                              <Text>{showedLotto.ipfs_cid}</Text>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                  <GridItem colSpan={1}></GridItem>
                </SimpleGrid>
              </Skeleton>
            )
          }
          {message.show && (
            <Alert marginTop={"20px"} status={message.status}>
              <AlertIcon />
              <Text>{message.text}</Text>
            </Alert>
          )}
        </Box>
      </Layout>
    </ProtectedPage>
  );
}
