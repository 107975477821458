import React, { useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Progress,
  Select,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import ProtectedPage from '../ProtectedPage'
import Layout from '../Layout'
import { Field, Form, Formik } from 'formik'
import {
  FISH_TOOLS,
  FISH_TOOLS_LABELS,
  Lotto,
  LottoSchema,
} from '../../constants/lotto'
import FormInputField from '../common/FormInputField'
import { useDispatch } from 'react-redux'
import { createNewLot } from '../../redux/lots/backendActions'
import { MessageStatuses } from '../../constants/general'

export function InserisciLotto() {
  const [wait, setWait] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    status: MessageStatuses.SUCCESS,
    text: "",
  });
  const dispatch = useDispatch()
  return (
    <ProtectedPage>
      <Layout>
        <Box width={"100%"} bg={"white"} padding={"10px"}>
          <Formik
            initialValues={new Lotto()}
            onSubmit={(values) => {
              setWait(true)
              dispatch(createNewLot(values)).then(
                (data) => {
                  setMessage({
                    show: true,
                    status: MessageStatuses.SUCCESS,
                    text: "Lotto " + values["lot_identification_string"] + " memorizzato in blockchain correttamente con Algorand application id " + data.algorand_id,
                  });
                }
              ).catch( () => {
                setMessage({
                  show: true,
                  status: MessageStatuses.ERROR,
                  text: "Errore memorizzazione lotto " + values["lot_identification_string"],
                });
              }).finally(() => {
                setWait(false)
              })
            }}
            validationSchema={LottoSchema}
            enableReinitialize
          >
            <Form>
              <VStack spacing={3} alignItems="flex-start">
                <Heading as="h4" size="md">
                  Inserimento lotto pesce
                </Heading>
              </VStack>
              <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"lot_identification_string"}
                    type={"text"}
                    label={"Identificativo partita"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"position_lat"}
                    type={"text"}
                    label={"Posizione pesca (latitudine)"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"position_long"}
                    type={"text"}
                    label={"Posizione pesca (longitudine)"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"ship_ue_identification_number"}
                    type={"text"}
                    label={"Identificativo UE imbarcazione"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"ship_name"}
                    type={"text"}
                    label={"Nome imbarcazione"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"fish_time"}
                    type={"datetime-local"}
                    label={"Data di pesca"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <Field name={"fish_tool"}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors["fish_tool"] && form.touched["fish_tool"]
                        }
                        isRequired={true}
                        disabled={wait}
                      >
                        <FormLabel>
                          {"Tipo di attrezzo di pesca utilizzato"}
                        </FormLabel>
                        <Select
                          name={"fish_tool"}
                          placeholder="Seleziona attrezzo"
                          size="lg"
                          variant="filled"
                          onChange={field.onChange}
                          value={field.value}
                          disabled={wait}
                        >
                          {Object.keys(FISH_TOOLS).map((id) => {
                            return (
                              <option key={id} value={FISH_TOOLS[id]}>
                                {FISH_TOOLS_LABELS[id]}
                              </option>
                            );
                          })}
                        </Select>
                        <FormErrorMessage>
                          {form.errors["fish_tool"]}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"landing_port"}
                    type={"text"}
                    label={"Porto di sbarco"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormInputField
                    name={"landing_port_time"}
                    type={"datetime-local"}
                    label={"Data di sbarco/di prima vendita"}
                    required={true}
                    disabled={wait}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Button
                    colorScheme="blue"
                    type="submit"
                    size="lg"
                    w="full"
                    disabled={wait}
                  >
                    Crea lotto
                  </Button>
                </GridItem>
                {wait && (
                  <GridItem colSpan={2}>
                    <Progress size="md" isIndeterminate />
                  </GridItem>
                )}
              </SimpleGrid>
            </Form>
          </Formik>
          {message.show && (
            <Alert marginTop={"20px"} status={message.status}>
              <AlertIcon />
              <Text>{message.text}</Text>
            </Alert>
          )}
        </Box>
      </Layout>
    </ProtectedPage>
  );
}
