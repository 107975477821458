import * as Yup from "yup";

export const FISH_TOOLS = Object.freeze({
  RETI_VOLANTI: "reti_volanti",
  RETI_CIRCUIZIONE: "reti_circuizione"
})

export const FISH_TOOLS_LABELS = Object.freeze({
  RETI_VOLANTI: "Reti volanti",
  RETI_CIRCUIZIONE: "Reti a circuizione"
})

export class Lotto {
  constructor () {
    this.lot_identification_string = "";
    this.position_lat = "";
    this.position_long = "";
    this.ship_ue_identification_number = ""
    this.ship_name = ""
    this.fish_time = ""
    this.fish_tool = FISH_TOOLS.RETI_CIRCUIZIONE
    this.landing_port = ""
    this.landing_port_time = ""
  }
}

export class PublicLotto extends Lotto{
  constructor () {
    super()
    this.algorand_id = ""
  }
}

export class FullSearchLotto extends PublicLotto{
  constructor () {
    super()
    this.appid = 0
    this.lot_id = 0
    this.lot_url = ""
    this.sha256_lot_hash= ""
    this.ipfs_cid = ""
  }
}

export const LottoSchema = Yup.object().shape({
  lot_identification_string: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  position_lat: Yup.string()
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  position_long: Yup.string()
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  ship_ue_identification_number: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  ship_name: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  fish_time: Yup.date()
    .required("Campo obbligatorio"),
  fish_tool: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  landing_port: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  landing_port_time: Yup.date()
    .required("Campo obbligatorio"),
});